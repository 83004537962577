<template>
  <div id="userPropertyFilterPopup">
    <div class="allUsers" v-if="!fetchingUserProperty" v-bind:class="{ readOnly: readOnly === true }">
      <template v-if="!readOnly">
        <div class="text">{{ filterTitle }}</div>
      </template>
      <template v-else>
        <span v-if="userPropertyFilter.filters.length > 0 || userPropertyFilter.performedFilter.length > 0 || userPropertyFilter.notPerformedFilter.length > 0"> All users with following criteria: </span>
        <span v-else> All Users </span>
      </template>
    </div>

    <div class="filterList border-l" v-bind:class="{ readOnly: readOnly === true }" v-if="!fetchingUserProperty">
      <!-- User Attribute Filter List -->
      <div v-if="userPropertyFilter.filters.length > 0">
        <div class="propertyFilter position-relative" v-for="(subFilter, index1) in userPropertyFilter.filters" :key="index1">
          <hr style="position: absolute; width: 20px; top: 18px; left: 0px" />
          <!-- And/Or Condition -->
          <div v-if="!readOnly" style="font-size: 12px; color: #444; line-height: 26px; width: 80px; text-align: right; display: inline-block">
            <template v-if="index1 != 0">
              <el-radio-group class="andOrSwitch" size="mini" v-model="userPropertyFilter.matchAll">
                <el-radio-button :label="true">and</el-radio-button>
                <el-radio-button :label="false">or</el-radio-button>
              </el-radio-group>
            </template>
            <div v-else style="display: inline-block; border: 1px solid #ddd; border-radius: 3px; width: 65px; line-height: 24px; text-align: center; margin-top: -2px">where&nbsp;</div>
          </div>
          <div v-else style="margin-left: 22px; font-size: 14px; padding-right: 5px; display: inline-block; line-height: 24px; text-align: center; margin-top: 2px">
            <template v-if="index1 == 0"> </template>
            <template v-else>
              {{ userPropertyFilter.matchAll ? 'and' : 'or' }}
            </template>
          </div>

          <!-- Select Filter Property -->
          <el-select v-if="!readOnly" class="smallDropdown propertyName" style="max-width: 250px" size="mini" autocomplete="off" v-model="subFilter.propertyName" filterable @change="onFilterUserPropertyChange(subFilter, userPropertyList)" placeholder="Select Property">
            <el-option v-for="property in userPropertyList" :key="property.columnName" :label="property.name" :value="property.columnName"></el-option>
          </el-select>
          <span class="readonlyField" v-else>
            {{ subFilter.propertyInfo.name }}
          </span>

          <!-- Select Comparision Type -->
          <template v-if="subFilter.comparisonTypeList">
            <el-select v-if="!readOnly" class="smallDropdown comparisionSelector" size="mini" v-model="subFilter.comparisonType" filterable placeholder="Select Event Property">
              <el-option v-for="compType in subFilter.comparisonTypeList" :key="compType.label" :label="compType.label" :value="compType.key"></el-option>
            </el-select>
            <span class="readonlyExtraField" v-else>
              {{ getDisplayText(subFilter.comparisonType, subFilter.comparisonTypeList) }}
            </span>
          </template>

          <!-- Time - Compare Current with Future/Past Time -->
          <template v-if="['time-future-greater-than', 'time-future-less-than', 'time-past-greater-than', 'time-past-less-than'].includes(subFilter.comparisonType)">
            <div class="filterInputContainer inputContianer">
              <el-input class="w-24" size="mini" v-if="!readOnly" placeholder="Enter minutes" v-model="subFilter.value" type="number"></el-input>
              <span class="readonlyField" v-else> {{ subFilter.value }}</span>
              <span style="font-size: 10px; line-height: 10px">Minutes From Current Time</span>
            </div>
          </template>

          <!-- Input Field List Based on types -->
          <template v-else-if="subFilter.comparisonType && subFilter.comparisonType != 'is not null' && subFilter.comparisonType != 'is null'">
            <!-- Render Date For Date Type -->
            <template v-if="subFilter.propertyInfo.type == 'date'">
              <el-date-picker v-if="!readOnly" class="inputFilter" size="mini" v-model="subFilter.value" type="datetime" placeholder="Select date and time"></el-date-picker>
              <span class="readonlyField" v-else>{{ subFilter.value }}</span>
            </template>

            <!-- Render Number input for number type -->
            <template v-if="subFilter.propertyInfo.type == 'decimal' || subFilter.propertyInfo.type == 'double precision'">
              <el-input class="smallDropdown w-full" size="mini" v-if="!readOnly" placeholder="Enter value" v-model="subFilter.value" type="number"></el-input>
              <span class="readonlyField" v-else>{{ subFilter.value }}</span>
            </template>

            <!-- Render Searchable String for string type  -->
            <template v-if="subFilter.propertyInfo.type == 'string'">
              <el-autocomplete autocomplete="off" class="smallDropdown w-full" size="mini" v-if="!readOnly" placeholder="Enter search term" @focus="setCurrentUserProperty(subFilter)" v-model="subFilter.value" :fetch-suggestions="fetchUserPropertySuggestion" clearable></el-autocomplete>
              <span class="readonlyField" v-else>{{ subFilter.value }}</span>
            </template>

            <!-- Boolean - Yes/No dropdown -->
            <template v-if="subFilter.propertyInfo.type == 'boolean'">
              <el-select size="mini" class="smallDropdown w-full" v-if="!readOnly" v-model="subFilter.value">
                <el-option label="True/Yes" value="true"></el-option>
                <el-option label="False/No" value="false"></el-option>
              </el-select>
              <span class="readonlyField" v-else>{{ subFilter.value }}</span>
            </template>

            <!-- Text Array -->
            <template v-if="subFilter.propertyInfo.type == 'text array' || subFilter.propertyInfo.type == 'string array'">
              <el-input size="small" class="smallDropdown w-full" v-if="!readOnly" placeholder="Enter comma seperated list items to match" v-model="subFilter.value"></el-input>
              <span class="readonlyField" v-else>{{ subFilter.value }}</span>
            </template>
          </template>

          <!-- Action Buttons -->
          <el-dropdown trigger="click" v-if="!readOnly" @command="handleUserFilterAction(userPropertyFilter.filters, index1, $event)">
            <i class="text-lg fa-duotone fa-circle-chevron-down"></i>
            <template #dropdown>
              <el-dropdown-menu trigger="click">
                <el-dropdown-item command="clone">
                  <i class="fa-solid fa-copy text-primary-600"></i>
                  Duplicate
                </el-dropdown-item>
                <el-dropdown-item command="delete" :divided="true">
                  <i class="fa-regular fa-trash-can text-red-500"></i>
                  Delete
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>

      <!-- Performed Event - Container -->
      <div v-if="userPropertyFilter.performedFilter.length > 0">
        <div class="eventFilter position-relative" v-for="(eventFilter, index1) in userPropertyFilter.performedFilter" :key="index1">
          <hr style="position: absolute; width: 20px; top: 20px; left: 0px" />

          <!-- Header - When Event Not Selected Yet -->
          <div class="evenSelector" v-if="eventFilter.eventName == null">
            <!-- And/Or Condition -->
            <div v-if="!readOnly" style="font-size: 12px; color: #444; line-height: 26px; width: 80px; text-align: right; display: inline-block">
              <template v-if="index1 != 0 || userPropertyFilter.filters.length > 0">
                <el-radio-group class="andOrSwitch" size="mini" v-model="userPropertyFilter.matchAll">
                  <el-radio-button :label="true">and</el-radio-button>
                  <el-radio-button :label="false">or</el-radio-button>
                </el-radio-group>
              </template>
              <div v-else style="display: inline-block; border: 1px solid #ddd; border-radius: 3px; width: 65px; line-height: 24px; text-align: center; margin-top: -2px">where&nbsp;</div>
            </div>
            <div v-else style="margin-left: 22px; font-size: 14px; padding-right: 5px; display: inline-block; line-height: 24px; text-align: center; margin-top: 2px">
              <template v-if="index1 != 0 || userPropertyFilter.filters.length > 0">{{ userPropertyFilter.matchAll ? 'and' : 'or' }}</template>
              <template v-else> </template>
            </div>

            <div style="padding-left: 5px; font-size: 14px; display: inline-block">who performed</div>

            <el-select class="smallDropdown" size="mini" v-model="eventFilter.eventName" filterable v-if="!readOnly" @change="onEventSelect(eventFilter)" placeholder="Select Event Performed">
              <el-option v-for="eventName in eventList" :key="eventName" :label="eventName" :value="eventName"> </el-option>
            </el-select>
            <span class="readonlyField" v-else>{{ eventFilter.eventName }}</span>

            <!-- Delete Button -->
            <el-button class="deleteBtn" icon="el-icon-delete" v-if="!readOnly" @click="deleteEventFilter(userPropertyFilter.performedFilter, index1)" type="text" size="mini"> </el-button>
          </div>

          <!-- Header - When Event Selected -->
          <div class="eventFilterHeader" v-if="eventFilter.eventName">
            <!-- And/Or Condition -->
            <div v-if="!readOnly" style="font-size: 12px; color: #444; line-height: 26px; width: 80px; text-align: right; display: inline-block">
              <template v-if="index1 != 0 || userPropertyFilter.filters.length > 0">
                <el-radio-group class="andOrSwitch" size="mini" v-model="userPropertyFilter.matchAll">
                  <el-radio-button :label="true">and</el-radio-button>
                  <el-radio-button :label="false">or</el-radio-button>
                </el-radio-group>
              </template>
              <div v-else style="display: inline-block; border: 1px solid #ddd; border-radius: 3px; width: 65px; line-height: 24px; text-align: center; margin-top: -2px">where&nbsp;</div>
            </div>
            <div v-else style="margin-left: 22px; font-size: 14px; padding-right: 5px; display: inline-block; line-height: 24px; text-align: center; margin-top: 2px">
              <template v-if="index1 != 0 || userPropertyFilter.filters.length > 0">{{ userPropertyFilter.matchAll ? 'and' : 'or' }}</template>
              <template v-else>where </template>
            </div>

            <!-- Event Name with count filter -->
            <div style="padding-left: 9px; font-size: 14px; display: inline-block">
              who <strong>performed {{ eventFilter.eventName }}</strong>
            </div>

            <!-- Count Input Comparision Type -->
            <el-select size="mini" class="comparisionOperator" :style="{ width: eventFilter.countComparisonType == 'between' ? '60px' : '30px' }" v-model="eventFilter.countComparisonType" slot="prepend" v-if="!readOnly" placeholder=">=">
              <el-option label=">=" value=">="></el-option>
              <el-option label="<=" value="<="></el-option>
              <el-option label="=" value="="></el-option>
              <el-option label="between" value="between"></el-option>
            </el-select>

            <!-- Count Input -->
            <template v-if="eventFilter.countComparisonType == 'between'">
              <el-input size="mini" class="eventCountInput" v-model="eventFilter.countRange[0]" v-if="!readOnly"></el-input>
              <span v-if="!readOnly">to</span>
              <el-input size="mini" class="eventCountInput" v-model="eventFilter.countRange[1]" v-if="!readOnly"></el-input>
              <!-- {{ eventFilter }} -->
              <span class="readonlyField" v-else> {{ eventFilter.countRange[0] }} to {{ eventFilter.countRange[1] }}</span>
            </template>
            <template v-else>
              <el-input size="mini" class="eventCountInput" v-model="eventFilter.count" v-if="!readOnly"></el-input>
              <span class="readonlyField" v-else>
                {{ eventFilter.countComparisonType }}
                {{ eventFilter.count }}
              </span>
            </template>
            <span v-if="eventFilter.count == 1">{{ readOnly ? ' time, ' : ' time ' }}</span>
            <span v-else>{{ readOnly ? ' times, ' : ' times ' }}</span>

            <!-- Duration Filter -->
            <el-popover placement="bottom" width="450" trigger="click" v-model="eventFilter.showSelector">
              <!-- Days Range -->
              <el-tabs v-model="eventFilter.duration.type" @tab-click="onDurationTypeChange(eventFilter)">
                <el-tab-pane label="In last" name="in_last">
                  <!-- Single Day -->
                  <el-select v-if="eventFilter.duration.number.comparisonType != 'between'" v-model="eventFilter.duration.number.value" placeholder="Select" size="mini" filterable>
                    <el-option v-for="item in get365NumberList()" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>

                  <!-- Day Range -->
                  <span v-else>
                    <el-select style="width: 100px" v-model="eventFilter.duration.number.valueRange[0]" placeholder="Start" size="mini" filterable>
                      <el-option v-for="item in get365NumberList()" :key="item" :label="item" :value="item"> </el-option>
                    </el-select>
                    -
                    <el-select style="width: 100px" v-model="eventFilter.duration.number.valueRange[1]" placeholder="End" size="mini" filterable>
                      <el-option v-for="item in get365NumberList()" :key="item" :label="item" :value="item"> </el-option>
                    </el-select>
                  </span>

                  <el-select v-model="eventFilter.duration.number.valueUnit" placeholder="Select" size="mini">
                    <el-option v-for="item in ['minutes', 'hours', 'days', 'months']" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>

                  <div style="margin-top: 20px">
                    <el-switch v-model="eventFilter.duration.number.comparisonType" active-value="between" inactive-value=">="> </el-switch>
                    Use Day Range
                  </div>
                </el-tab-pane>

                <el-tab-pane label="Exactly" name="exactly">
                  <!-- // days ago -->
                  <el-select v-model="eventFilter.duration.number.value" placeholder="Select" size="mini" filterable>
                    <el-option v-for="item in get365NumberList()" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>
                  day(s) ago
                </el-tab-pane>

                <el-tab-pane label="Before" name="before">
                  <!-- // before a particular timestamp -->
                  <el-date-picker v-model="eventFilter.duration.date.value" type="datetime" placeholder="Select date and time"> </el-date-picker>
                </el-tab-pane>

                <el-tab-pane label="Between" name="between">
                  <!-- // between two timestamp range -->
                  <el-date-picker v-model="eventFilter.duration.date_range.value" type="datetimerange" start-placeholder="Start Date" end-placeholder="End Date" :default-time="['12:00:00']"> </el-date-picker>
                </el-tab-pane>

                <el-tab-pane label="After" name="after">
                  <!-- // after a particular timestamp -->
                  <el-date-picker v-model="eventFilter.duration.date.value" type="datetime" placeholder="Select date and time"> </el-date-picker>
                </el-tab-pane>
              </el-tabs>

              <!-- Hours Range -->
              <div label="In Hours Of Day" style="padding: 10px 0px 20px 0px; border-top: 1px solid #ddd; margin-top: 10px">
                <template v-if="eventFilter.duration.hours">
                  <el-switch v-model="eventFilter.duration.hours.enabled" :active-value="true" :inactive-value="false" style="margin-top: -5px"> </el-switch>
                  &nbsp;
                  <template v-if="eventFilter.duration.hours.enabled">
                    From&nbsp;
                    <el-select style="width: 110px" v-model="eventFilter.duration.hours.start" size="mini" filterable>
                      <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"> </el-option>
                    </el-select>
                    &nbsp;To&nbsp;
                    <el-select style="width: 110px" v-model="eventFilter.duration.hours.end" size="mini" filterable>
                      <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"> </el-option>
                    </el-select>
                    &nbsp;
                  </template>
                  <div v-else style="display: inline-block">Filter By Hours Of Day</div>
                </template>
              </div>

              <!-- Confirm Buttons -->
              <div style="margin: 0">
                <el-button type="primary" size="mini" @click="confirmEventDuration(eventFilter)">Apply Changes</el-button>
                <el-button size="mini" type="danger" plain @click="eventFilter.showSelector = false">Cancel</el-button>
              </div>

              <!-- Reference Slot -->
              <el-button size="mini" type="info" plain slot="reference" :disabled="readOnly">
                <template v-if="eventFilter.duration.type == 'in_last'">
                  in last
                  <template v-if="eventFilter.duration.number.comparisonType == 'between'"> {{ eventFilter.duration.number.valueRange[0] }} to {{ eventFilter.duration.number.valueRange[1] }} </template>
                  <template v-else>
                    {{ eventFilter.duration.number.value }}
                  </template>
                  {{ eventFilter.duration.number.valueUnit }}
                </template>

                <template v-else-if="eventFilter.duration.type == 'exactly'"> {{ eventFilter.duration.number.value }} day(s) ago </template>
                <template v-else-if="eventFilter.duration.type == 'before'"> before {{ eventFilter.duration.date.value }} </template>
                <template v-else-if="eventFilter.duration.type == 'after'"> after {{ eventFilter.duration.date.value }} </template>
                <template v-else-if="eventFilter.duration.type == 'between'"> between {{ eventFilter.duration.date_range.value[0] }} - {{ eventFilter.duration.date_range.value[1] }} </template>
              </el-button>
            </el-popover>

            <!-- Action Buttons -->
            <el-dropdown trigger="click" v-if="!readOnly" @command="handlePerformedFilter(userPropertyFilter.performedFilter, index1, $event)">
              <i class="text-lg fa-duotone fa-circle-chevron-down"></i>
              <template #dropdown>
                <el-dropdown-menu trigger="click">
                  <el-dropdown-item command="clone">
                    <i class="fa-solid fa-copy text-primary-600"></i>
                    Duplicate
                  </el-dropdown-item>
                  <el-dropdown-item command="delete" :divided="true">
                    <i class="fa-regular fa-trash-can text-red-500"></i>
                    Delete
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>

          <!-- Performed - Filter List -->
          <div class="eventFilterBody" :class="{ isReadOnly: readOnly ? true : false }" v-if="eventFilter.eventName">
            <!-- Filter List Of Particular Event -->
            <div class="eventPropertyFilter" v-for="(subFilter, index1) in eventFilter.filters" :key="index1">
              <hr style="margin-left: 0px; width: 15px; margin-top: 13px" />
              <!-- And/Or Condition -->

              <div v-if="!readOnly" style="font-size: 12px; color: #444; line-height: 26px; width: 70px; text-align: left">
                <template v-if="index1 != 0">
                  <el-radio-group class="andOrSwitch" size="mini" v-model="eventFilter.matchAnyOfSubFilters">
                    <el-radio-button :label="false">and</el-radio-button>
                    <el-radio-button :label="true">or</el-radio-button>
                  </el-radio-group>
                </template>
                <div v-else style="display: inline-block; border: 1px solid #ddd; border-radius: 3px; width: 65px; line-height: 24px; text-align: center; margin-top: -2px">where&nbsp;</div>
              </div>
              <div v-else style="font-size: 12px; color: #444; line-height: 30px; padding-left: 10px; margin-right: 5px; text-align: left">
                <template v-if="index1 == 0">where</template>
                <template v-else>
                  {{ eventFilter.matchAnyOfSubFilters ? 'Or' : 'and' }}
                </template>
              </div>

              <!-- Select Filter Property -->
              <el-select class="smallDropdown" size="mini" v-model="subFilter.propertyName" filterable v-if="!readOnly" @change="onFilterPropertyChange(subFilter, eventFilter.properties)" placeholder="Select Property">
                <el-option v-for="property in eventFilter.properties" :key="property.name" :label="property.name" :value="property.name"></el-option>
              </el-select>
              <span class="readonlyField" v-else> {{ subFilter.propertyName }} </span>

              <!-- Select Comparision Type -->
              <template v-if="subFilter.comparisonTypeList">
                <el-select class="smallDropdown" size="mini" v-if="!readOnly" v-model="subFilter.comparisonType" filterable placeholder="Select Event Property">
                  <el-option v-for="(compType, index2) in subFilter.comparisonTypeList" :key="index2" :label="compType.label" :value="compType.key"></el-option>
                </el-select>
                <span class="readonlyExtraField" v-else>
                  {{ getDisplayText(subFilter.comparisonType, subFilter.comparisonTypeList) }}
                </span>
              </template>

              <!-- Input Field List Based on types -->
              <template v-if="subFilter.comparisonType && subFilter.comparisonType != 'is not null' && subFilter.comparisonType != 'is null'">
                <div class="filterInputContainer">
                  <!-- Render Date For Date Type -->
                  <div v-if="subFilter.propertyInfo.type == 'date'">
                    <el-date-picker size="mini" v-model="subFilter.value" type="datetime" v-if="!readOnly" placeholder="Select date and time"></el-date-picker>
                    <span class="readonlyField" v-else>{{ subFilter.value }}</span>
                  </div>

                  <!-- Render Number input for number type -->
                  <div v-if="subFilter.propertyInfo.type == 'decimal' || subFilter.propertyInfo.type == 'number' || subFilter.propertyInfo.type == 'bigint'">
                    <el-input size="mini" placeholder="Enter value" v-model="subFilter.value" type="number" v-if="!readOnly"> </el-input>
                    <span class="readonlyField" v-else>{{ subFilter.value }}</span>
                  </div>

                  <!-- Render Searchable String for string type  -->

                  <div v-if="subFilter.propertyInfo.type == 'string'">
                    <el-autocomplete autocomplete="off" class="inline-input w-full" size="mini" placeholder="Enter search term" v-if="!readOnly" @focus="setCurrentEventData(eventFilter.eventName, subFilter)" v-model="subFilter.value" :fetch-suggestions="fetchEventPropertySuggestion" clearable></el-autocomplete>
                    <span class="readonlyField" v-else>{{ subFilter.value }}</span>
                  </div>

                  <!-- Boolean - Yes/No dropdown -->
                  <div v-if="subFilter.propertyInfo.type == 'boolean'">
                    <el-select size="mini" v-model="subFilter.value" v-if="!readOnly">
                      <el-option label="True/Yes" value="true"></el-option>
                      <el-option label="False/No" value="false"></el-option>
                    </el-select>
                    <span class="readonlyField" v-else>{{ subFilter.value }}</span>
                  </div>

                  <!-- Text Array -->
                  <template v-if="subFilter.propertyInfo.type == 'text array' || subFilter.propertyInfo.type == 'string array'">
                    <el-input size="small" class="smallDropdown w-full" v-if="!readOnly" placeholder="Enter comma seperated list items to match" v-model="subFilter.value"></el-input>
                    <span class="readonlyField" v-else>{{ subFilter.value }}</span>
                  </template>
                </div>
              </template>

              <!-- Action Buttons -->
              <el-dropdown trigger="click" v-if="!readOnly" @command="handlePerformedSubFilter(eventFilter.filters, index1, $event)">
                <i class="text-lg fa-duotone fa-circle-chevron-down"></i>
                <template #dropdown>
                  <el-dropdown-menu trigger="click">
                    <el-dropdown-item command="clone">
                      <i class="fa-solid fa-copy text-primary-600"></i>
                      Duplicate
                    </el-dropdown-item>
                    <el-dropdown-item command="delete" :divided="true">
                      <i class="fa-regular fa-trash-can text-red-500"></i>
                      Delete
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>

            <!------------------ event filter button ----------------->
            <div style="padding-left: 16px" v-if="!readOnly">
              <el-button icon="el-icon-plus" type="info" plain size="mini" @click="addEventPropertyFilter(eventFilter)"> Add {{ eventFilter.eventName }} Condition</el-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Not Performed - Container -->
      <div v-if="userPropertyFilter.notPerformedFilter.length > 0">
        <div class="eventFilter position-relative" v-for="(eventFilter, index1) in userPropertyFilter.notPerformedFilter" :key="index1">
          <hr style="position: absolute; width: 20px; top: 21px; left: 0px" />

          <!-- Not Performed - Header - Event Not Selected -->
          <div class="evenSelector" v-if="eventFilter.eventName == null">
            <!-- And/Or Condition -->
            <div v-if="!readOnly" style="font-size: 12px; color: #444; line-height: 26px; width: 80px; text-align: right; display: inline-block">
              <template v-if="userPropertyFilter.filters.length > 0 || userPropertyFilter.performedFilter.length > 0 || index1 != 0">
                <el-radio-group class="andOrSwitch" size="mini" v-model="userPropertyFilter.matchAll">
                  <el-radio-button :label="true">and</el-radio-button>
                  <el-radio-button :label="false">or</el-radio-button>
                </el-radio-group>
              </template>
              <div v-else style="display: inline-block; border: 1px solid #ddd; border-radius: 3px; width: 65px; line-height: 24px; text-align: center; margin-top: -2px">where&nbsp;</div>
            </div>
            <div v-else style="margin-left: 22px; font-size: 14px; padding-right: 5px; display: inline-block; line-height: 24px; text-align: center; margin-top: 2px">
              <template v-if="userPropertyFilter.filters.length > 0 || userPropertyFilter.performedFilter.length > 0 || index1 != 0">{{ userPropertyFilter.matchAll ? 'and' : 'or' }}</template>
              <template v-else> where </template>
            </div>

            <div style="padding-left: 5px; font-size: 14px; display: inline-block">who did not perform</div>

            <el-select v-if="!readOnly" class="eventSelectDropdown" size="mini" v-model="eventFilter.eventName" filterable @change="onEventSelect(eventFilter)" placeholder="Select Event Not Performed">
              <el-option v-for="eventName in eventList" :key="eventName" :label="eventName" :value="eventName"> </el-option>
            </el-select>
            <span class="readonlyField font-bold" v-else>{{ eventFilter.eventName }}</span>

            <!-- Delete Button -->
            <el-button class="deleteBtn" icon="el-icon-delete" v-if="!readOnly" @click="deleteEventFilter(userPropertyFilter.notPerformedFilter, index1)" type="text" size="mini"> </el-button>
          </div>

          <!-- Not Performed - Header - Event Selected -->
          <div class="eventFilterHeader" v-if="eventFilter.eventName">
            <!-- And/Or Condition -->
            <div v-if="!readOnly" style="font-size: 12px; color: #444; line-height: 26px; width: 80px; text-align: right; display: inline-block">
              <template v-if="userPropertyFilter.filters.length > 0 || userPropertyFilter.performedFilter.length > 0 || index1 != 0">
                <el-radio-group class="andOrSwitch" size="mini" v-model="userPropertyFilter.matchAll">
                  <el-radio-button :label="true">and</el-radio-button>
                  <el-radio-button :label="false">or</el-radio-button>
                </el-radio-group>
              </template>
              <div v-else style="display: inline-block; border: 1px solid #ddd; border-radius: 3px; width: 65px; line-height: 24px; text-align: center; margin-top: -2px">where&nbsp;</div>
            </div>
            <div v-else style="margin-left: 22px; font-size: 14px; padding-right: 5px; display: inline-block; line-height: 24px; text-align: center; margin-top: 2px">
              <template v-if="userPropertyFilter.filters.length > 0 || userPropertyFilter.performedFilter.length > 0 || index1 != 0">{{ userPropertyFilter.matchAll ? 'and' : 'or' }}</template>
              <template v-else> where </template>
            </div>

            <!-- Event Name with count filter -->
            <div style="padding-left: 5px; font-size: 14px; display: inline-block">
              who <strong>did not</strong> perform <strong>{{ eventFilter.eventName }}</strong>
            </div>
            &nbsp;

            <!-- Duration Filter -->
            <template v-if="!readOnly">
              <el-popover placement="bottom" width="450" trigger="click" v-model="eventFilter.showSelector">
                <el-tabs v-model="eventFilter.duration.type">
                  <el-tab-pane label="In last" name="in_last">
                    <!-- Single Day -->
                    <el-select v-if="eventFilter.duration.number.comparisonType != 'between'" v-model="eventFilter.duration.number.value" placeholder="Select" size="mini" filterable>
                      <el-option v-for="item in get365NumberList()" :key="item" :label="item" :value="item"> </el-option>
                    </el-select>

                    <!-- Day Range -->
                    <span v-else>
                      <el-select style="width: 100px" v-model="eventFilter.duration.number.valueRange[0]" placeholder="Start" size="mini" filterable>
                        <el-option v-for="item in get365NumberList()" :key="item" :label="item" :value="item"> </el-option>
                      </el-select>
                      -
                      <el-select style="width: 100px" v-model="eventFilter.duration.number.valueRange[1]" placeholder="End" size="mini" filterable>
                        <el-option v-for="item in get365NumberList()" :key="item" :label="item" :value="item"> </el-option>
                      </el-select>
                    </span>

                    <el-select v-model="eventFilter.duration.number.valueUnit" placeholder="Select" size="mini">
                      <el-option v-for="item in ['minutes', 'hours', 'days', 'months']" :key="item" :label="item" :value="item"> </el-option>
                    </el-select>

                    <div style="margin-top: 20px">
                      <el-switch v-model="eventFilter.duration.number.comparisonType" active-value="between" inactive-value=">="> </el-switch>
                      Use Day Range
                    </div>
                  </el-tab-pane>

                  <el-tab-pane label="Exactly" name="exactly">
                    <!-- // days ago -->
                    <el-select v-model="eventFilter.duration.number.value" placeholder="Select" size="mini" filterable v-if="!readOnly">
                      <el-option v-for="item in get365NumberList()" :key="item" :label="item" :value="item"> </el-option>
                    </el-select>
                    day(s) ago
                  </el-tab-pane>

                  <!-- // before a particular timestamp -->
                  <el-tab-pane label="Before" name="before">
                    <el-date-picker v-model="eventFilter.duration.date.value" type="datetime" v-if="!readOnly" placeholder="Select date and time"> </el-date-picker>
                  </el-tab-pane>

                  <!-- // between two timestamp range -->
                  <el-tab-pane label="Between" name="between">
                    <el-date-picker v-model="eventFilter.duration.date_range.value" type="datetimerange" v-if="!readOnly" format="dd-MM-yyyy HH:mm" start-placeholder="Start Date" end-placeholder="End Date" :default-time="['12:00:00']"> </el-date-picker>
                  </el-tab-pane>

                  <!-- // after a particular timestamp -->
                  <el-tab-pane label="After" name="after">
                    <el-date-picker v-model="eventFilter.duration.date.value" type="datetime" v-if="!readOnly" placeholder="Select date and time"> </el-date-picker>
                  </el-tab-pane>
                </el-tabs>

                <!-- Hours Range -->
                <div label="In Hours Of Day" style="padding: 10px 0px 20px 0px; border-top: 1px solid #ddd; margin-top: 10px">
                  <template v-if="eventFilter.duration.hours">
                    <el-switch v-model="eventFilter.duration.hours.enabled" :active-value="true" :inactive-value="false" style="margin-top: -5px"> </el-switch>
                    &nbsp;
                    <template v-if="eventFilter.duration.hours.enabled">
                      From&nbsp;
                      <el-select style="width: 110px" v-model="eventFilter.duration.hours.start" size="mini" filterable>
                        <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"> </el-option>
                      </el-select>
                      &nbsp;To&nbsp;
                      <el-select style="width: 110px" v-model="eventFilter.duration.hours.end" size="mini" filterable>
                        <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"> </el-option>
                      </el-select>
                      &nbsp;
                    </template>
                    <div style="display: inline-block" v-else>Filter By Hours Of Day</div>
                  </template>
                </div>

                <!-- Confirm Button -->
                <div style="margin: 0">
                  <el-button type="primary" size="mini" @click="confirmEventDuration(eventFilter)">Apply Changes</el-button>
                  <el-button size="mini" type="danger" plain @click="eventFilter.showSelector = false">Cancel</el-button>
                </div>

                <!-- Placholder -->
                <el-button size="mini" type="info" plain slot="reference" :disabled="readOnly">
                  <template v-if="eventFilter.duration.type == 'in_last'">
                    in last
                    <template v-if="eventFilter.duration.number.comparisonType == 'between'"> {{ eventFilter.duration.number.valueRange[0] }} to {{ eventFilter.duration.number.valueRange[1] }} </template>
                    <template v-else>
                      {{ eventFilter.duration.number.value }}
                    </template>
                    {{ eventFilter.duration.number.valueUnit }}
                  </template>

                  <template v-if="eventFilter.duration.type == 'exactly'"> {{ eventFilter.duration.number.value }} day(s) ago </template>
                  <template v-if="eventFilter.duration.type == 'before'"> before {{ eventFilter.duration.date.value }} </template>
                  <template v-if="eventFilter.duration.type == 'after'"> after {{ eventFilter.duration.date.value }} </template>
                  <template v-if="eventFilter.duration.type == 'between'"> between {{ eventFilter.duration.date_range.value[0] }} - {{ eventFilter.duration.date_range.value[1] }} </template>
                </el-button>
              </el-popover>
            </template>
            <template v-else>
              <el-button size="mini" slot="reference" :disabled="readOnly">
                <template v-if="eventFilter.duration.type == 'in_last'">
                  in last
                  <template v-if="eventFilter.duration.number.comparisonType == 'between'"> {{ eventFilter.duration.number.valueRange[0] }} to {{ eventFilter.duration.number.valueRange[1] }} </template>
                  <template v-else>
                    {{ eventFilter.duration.number.value }}
                  </template>
                  {{ eventFilter.duration.number.valueUnit }}
                </template>

                <template v-if="eventFilter.duration.type == 'exactly'"> {{ eventFilter.duration.number.value }} day(s) ago </template>
                <template v-if="eventFilter.duration.type == 'before'"> before {{ eventFilter.duration.date.value }} </template>
                <template v-if="eventFilter.duration.type == 'after'"> after {{ eventFilter.duration.date.value }} </template>
                <template v-if="eventFilter.duration.type == 'between'"> between {{ eventFilter.duration.date_range.value[0] }} - {{ eventFilter.duration.date_range.value[1] }} </template>
              </el-button>
            </template>

            <!-- Action Buttons -->
            <div class="inline-block pl-1">
              <el-dropdown trigger="click" v-if="!readOnly" @command="handlePerformedFilter(userPropertyFilter.notPerformedFilter, index1, $event)">
                <i class="text-lg fa-duotone fa-circle-chevron-down"></i>
                <template #dropdown>
                  <el-dropdown-menu trigger="click">
                    <el-dropdown-item command="clone">
                      <i class="fa-solid fa-copy text-primary-600"></i>
                      Duplicate
                    </el-dropdown-item>
                    <el-dropdown-item command="delete" :divided="true">
                      <i class="fa-regular fa-trash-can text-red-500"></i>
                      Delete
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <!-- <el-button class="deleteBtn" icon="el-icon-delete" v-if="!readOnly" @click="deleteEventFilter(userPropertyFilter.notPerformedFilter, index1)" type="text" size="mini"> </el-button> -->
          </div>

          <!-- Not Performed Filter List -->
          <div class="eventFilterBody" :class="{ isReadOnly: readOnly ? true : false }" v-if="eventFilter.eventName">
            <!-- Filter List Of Particular Event -->
            <div class="eventPropertyFilter position-relative" v-for="(subFilter, index1) in eventFilter.filters" :key="index1">
              <hr style="margin-left: 0px; width: 15px; margin-top: 15px" />
              <!-- And/Or Condition -->
              <div v-if="!readOnly" style="font-size: 12px; color: #444; line-height: 26px; width: 70px; text-align: left">
                <template v-if="index1 != 0">
                  <el-radio-group class="andOrSwitch" size="mini" v-model="eventFilter.matchAnyOfSubFilters">
                    <el-radio-button :label="false">and</el-radio-button>
                    <el-radio-button :label="true">or</el-radio-button>
                  </el-radio-group>
                </template>
                <div v-else style="display: inline-block; border: 1px solid #ddd; border-radius: 3px; width: 65px; line-height: 24px; text-align: center; margin-top: -2px">where&nbsp;</div>
              </div>
              <div v-else style="font-size: 12px; color: #444; line-height: 30px; padding-left: 10px; margin-right: 5px; text-align: left">
                <template v-if="index1 == 0">where</template>
                <template v-else>
                  {{ eventFilter.matchAnyOfSubFilters ? 'Or' : 'and' }}
                </template>
              </div>

              <!-- Select Filter Property -->
              <el-select class="smallDropdown" size="mini" v-model="subFilter.propertyName" filterable v-if="!readOnly" @change="onFilterPropertyChange(subFilter, eventFilter.properties)" placeholder="Select Property">
                <el-option v-for="property in eventFilter.properties" :key="property.name" :label="property.name" :value="property.name"></el-option>
              </el-select>
              <span v-else>
                <span class="readonlyField">{{ subFilter.propertyName }}</span>
              </span>

              <!-- Select Comparision Type -->
              <template v-if="subFilter.comparisonTypeList">
                <el-select class="smallDropdown" size="mini" v-if="!readOnly" v-model="subFilter.comparisonType" filterable placeholder="Select Event Property">
                  <el-option v-for="(compType, index2) in subFilter.comparisonTypeList" :key="index2" :label="compType.label" :value="compType.key"></el-option>
                </el-select>
                <span class="readonlyExtraField" v-else>
                  {{ getDisplayText(subFilter.comparisonType, subFilter.comparisonTypeList) }}
                </span>
              </template>

              <!-- Input Field List Based on types -->
              <template v-if="subFilter.comparisonType && subFilter.comparisonType != 'is not null' && subFilter.comparisonType != 'is null'">
                <div class="filterInputContainer">
                  <!-- Render Date For Date Type -->
                  <div v-if="subFilter.propertyInfo.type == 'date'">
                    <el-date-picker size="mini" v-model="subFilter.value" type="datetime" v-if="!readOnly" placeholder="Select date and time"></el-date-picker>
                    <span class="readonlyField" v-else>{{ subFilter.value }}</span>
                  </div>

                  <!-- Render Number input for number type -->
                  <div v-if="subFilter.propertyInfo.type == 'decimal' || subFilter.propertyInfo.type == 'number' || subFilter.propertyInfo.type == 'bigint'">
                    <el-input size="mini" placeholder="Enter value" v-model="subFilter.value" type="number" v-if="!readOnly"> </el-input>
                    <span class="readonlyField" v-else>{{ subFilter.value }}</span>
                  </div>

                  <!-- Render Searchable String for string type  -->
                  <div v-if="subFilter.propertyInfo.type == 'string'">
                    <el-autocomplete autocomplete="off" class="inline-input w-full" size="mini" placeholder="Enter search term" v-if="!readOnly" @focus="setCurrentEventData(eventFilter.eventName, subFilter)" v-model="subFilter.value" :fetch-suggestions="fetchEventPropertySuggestion" clearable></el-autocomplete>
                    <span class="readonlyField" v-else>{{ subFilter.value }}</span>
                  </div>

                  <!-- Boolean - Yes/No dropdown -->
                  <div v-if="subFilter.propertyInfo.type == 'boolean'">
                    <el-select size="mini" v-model="subFilter.value" v-if="!readOnly">
                      <el-option label="True/Yes" value="true"></el-option>
                      <el-option label="False/No" value="false"></el-option>
                    </el-select>
                    <span class="readonlyField" v-else>{{ subFilter.value }}</span>
                  </div>

                  <!-- Text Array -->
                  <template v-if="subFilter.propertyInfo.type == 'text array' || subFilter.propertyInfo.type == 'string array'">
                    <el-input size="small" class="smallDropdown w-full" v-if="!readOnly" placeholder="Enter comma seperated list items to match" v-model="subFilter.value"></el-input>
                    <span class="readonlyField" v-else>{{ subFilter.value }}</span>
                  </template>
                </div>
              </template>

              <!-- Action Buttons -->
              <el-dropdown trigger="click" v-if="!readOnly" @command="handlePerformedSubFilter(eventFilter.filters, index1, $event)">
                <i class="text-lg fa-duotone fa-circle-chevron-down"></i>
                <template #dropdown>
                  <el-dropdown-menu trigger="click">
                    <el-dropdown-item command="clone">
                      <i class="fa-solid fa-copy text-primary-600"></i>
                      Duplicate
                    </el-dropdown-item>
                    <el-dropdown-item command="delete" :divided="true">
                      <i class="fa-regular fa-trash-can text-red-500"></i>
                      Delete
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>

            <!------------------ event filter button ----------------->
            <div style="padding-left: 16px" v-if="!readOnly">
              <el-button icon="el-icon-plus" type="info" plain size="mini" @click="addEventPropertyFilter(eventFilter)"> Add {{ eventFilter.eventName }} Condition</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Loader for fetching data -->
    <div class="loader" v-if="fetchingUserProperty">
      <beat-loader :loading="fetchingUserProperty" color="rgb(220, 223, 230)" size="8px"></beat-loader>
    </div>

    <!-- Add buttons -->
    <div class="addFilterButtons" v-if="!fetchingUserProperty && !readOnly">
      <el-button class="addFilterButton performedNotPerformedButton" icon="el-icon-plus" type="primary" plain size="mini" @click="addUserPropertyFilter()">User Attribute</el-button>
      <el-button class="addFilterButton performedNotPerformedButton" icon="el-icon-plus" type="primary" plain size="mini" @click="addUserPerformedEventFilter()">Performed Event</el-button>
      <el-button class="addFilterButton performedNotPerformedButton" icon="el-icon-plus" type="primary" plain size="mini" @click="addUserNotPerformedEventFilter()">Not Performed Event</el-button>
    </div>
  </div>
</template>

<style lang="scss" src="./userPropertyFilter.scss"></style>

<script>
import UserPropertyFilterComponent from './userPropertyFilterComponent';
export default UserPropertyFilterComponent;
</script>
