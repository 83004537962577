export default {
  methods: {
    getComparisonsForDatatype(dataType) {
      // equal, between ,greater than, less than, not equals, exists, does not exist

      if (['decimal', 'real', 'long', 'bigint', 'number', 'double precision'].indexOf(dataType) >= 0) {
        return [
          { label: 'greater than or equal', key: '>=' },
          { label: 'less than or equal', key: '<=' },
          { label: 'equal to', key: '=' },
          { label: 'not equal to', key: '!=' },
          { label: 'exists', key: 'is not null' },
          { label: 'does not exist', key: 'is null' }
        ];
      } else if (dataType == 'boolean') {
        return [
          { label: 'is', key: '=' },
          { label: 'is not', key: '!=' },
          { label: 'exists', key: 'is not null' },
          { label: 'does not exist', key: 'is null' }
        ];
      } else if (dataType == 'date' || dataType == 'timestamp') {
        return [
          { label: 'time greater than  or equal', key: '>=' },
          { label: 'time less than or equal', key: '<=' },
          { label: 'exists', key: 'is not null' },
          { label: 'does not exist', key: 'is null' },
          { label: 'time greater than future time', key: 'time-future-greater-than' },
          { label: 'time less than future time', key: 'time-future-less-than' },
          { label: 'time greater than past time', key: 'time-past-greater-than' },
          { label: 'time less than past time', key: 'time-past-less-than' }
        ];
      } else if (dataType == 'string array' || dataType == 'text array') {
        return [
          { label: '(any of) equals', key: 'array-equal-any' },
          { label: '(all of) equals', key: 'array-equal-all' },
          { label: '(any of) not equals', key: 'array-not-equal-any' },
          { label: '(all of) not equals', key: 'array-not-equal-all' },
          { label: 'exists', key: 'is not null' },
          { label: 'does not exist', key: 'is null' }
        ];
      } else {
        return [
          { label: 'contains', key: 'ilike' },
          { label: 'does not contain', key: 'not ilike' },
          { label: 'equals', key: '=' },
          { label: 'exists', key: 'is not null' },
          { label: 'does not exist', key: 'is null' }
        ];
      }
    }
  },
  data: function () {
    return {
      filterComparisonOptions: {
        String: [
          {
            name: 'is',
            type: 'String'
          },
          {
            name: 'is not',
            type: 'String'
          },
          {
            name: 'starts with',
            type: 'String'
          },
          {
            name: 'ends with',
            type: 'String'
          },
          {
            name: 'contains',
            type: 'String'
          },
          {
            name: 'does not contain',
            type: 'String'
          }
        ],
        IsRegistered: [
          {
            name: 'is registered'
          },
          {
            name: 'is not registered'
          }
        ],
        Date: [
          {
            name: 'before',
            type: 'Date'
          },
          {
            name: 'after',
            type: 'Date'
          },
          {
            name: 'on',
            type: 'Date'
          },
          {
            name: 'within last',
            type: 'Number',
            units: ['seconds', 'minutes', 'hours', 'days']
          },
          {
            name: 'not within last',
            type: 'Number',
            units: ['seconds', 'minutes', 'hours', 'days']
          },
          {
            name: 'in range',
            type: 'DateRange'
          },
          {
            name: 'not in range',
            type: 'DateRange'
          }
        ],
        UserLastSeen: [
          {
            name: 'before',
            type: 'Date'
          },
          {
            name: 'after',
            type: 'Date'
          },
          {
            name: 'on',
            type: 'Date'
          },
          {
            name: 'in range',
            type: 'DateRange'
          },
          {
            name: 'not in range',
            type: 'DateRange'
          },
          {
            name: 'within last',
            type: 'Number',
            units: ['hours', 'days']
          },
          {
            name: 'not within last',
            type: 'Number',
            units: ['hours', 'days']
          }
        ],
        Number: [
          {
            name: 'greater than',
            type: 'Number'
          },
          {
            name: 'less than',
            type: 'Number'
          },
          {
            name: 'equal',
            type: 'Number'
          },
          {
            name: 'not equal',
            type: 'Number'
          }
        ],
        UserProperty: [
          {
            name: 'is',
            type: 'KeyValue'
          }
        ],
        Country: [
          {
            name: 'is',
            type: 'Enum',
            options: this.getCountryMapArray()
          },
          {
            name: 'is not',
            type: 'Enum',
            options: this.getCountryMapArray()
          }
        ],
        FacingIssues: [
          {
            name: 'facing issues'
          },
          {
            name: 'not facing issues'
          }
        ],
        Duration: [
          {
            name: 'greater than',
            type: 'Number',
            units: ['seconds', 'minutes', 'hours', 'days']
          },
          {
            name: 'less than',
            type: 'Number',
            units: ['seconds', 'minutes', 'hours', 'days']
          },
          {
            name: 'equal',
            type: 'Number',
            units: ['seconds', 'minutes', 'hours', 'days']
          },
          {
            name: 'not equal',
            type: 'Number',
            units: ['seconds', 'minutes', 'hours', 'days']
          }
        ],
        Browser: [
          {
            name: 'is',
            type: 'Enum',
            options: ['Chromium', 'Chrome', 'FireFox', 'Edge', 'Safari', 'Internet Explorer', 'Android Browser', 'Mobile Safari']
          },
          {
            name: 'is not',
            type: 'Enum',
            options: ['Chromium', 'Chrome', 'FireFox', 'Edge', 'Safari', 'Internet Explorer', 'Android Browser', 'Mobile Safari']
          }
        ],
        Device: [
          {
            name: 'is',
            type: 'Enum',
            options: ['Mobile', 'Desktop', 'Tablet']
          },
          {
            name: 'is not',
            type: 'Enum',
            options: ['Mobile', 'Desktop', 'Tablet']
          }
        ],
        OS: [
          {
            name: 'is',
            type: 'Enum',
            options: ['iOS', 'Android', 'Windows', 'Mac OS', 'Linux', 'Ubuntu']
          },
          {
            name: 'is not',
            type: 'Enum',
            options: ['iOS', 'Android', 'Windows', 'Mac OS', 'Linux', 'Ubuntu']
          }
        ],
        CustomJsEvent: [
          {
            name: 'is',
            type: 'String'
          },
          {
            name: 'is not',
            type: 'String'
          },
          {
            name: 'starts with',
            type: 'String'
          },
          {
            name: 'ends with',
            type: 'String'
          },
          {
            name: 'contains',
            type: 'String'
          },
          {
            name: 'does not contain',
            type: 'String'
          },
          {
            name: 'has any value',
            type: 'String'
          },
          {
            name: 'has no value',
            type: 'String'
          }
        ]
      },
      pageVisitedOptions: [],
      trafficSourceOptions: [],
      clickedOnElementOptions: [],
      fetchingPageVisitedList: false,
      fetchingTrafficSourceList: false,
      fetchingClickedOnElementList: false
    };
  }
};
